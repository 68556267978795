<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-datepicker
                  :required="true"
                  :editable="false"
                  label="등록일자"
                  name="dailyResultDt"
                  v-model="data.dailyResultDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-field
                  :required="true"
                  :editable="false"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-plant :required="true" :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-select
                  :editable="false"
                  codeGroupCd="WEATHER_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="weatherCd"
                  label="날씨"
                  v-model="data.weatherCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="false"
                  label="온도"
                  suffix="℃"
                  name="temperature"
                  v-model="data.temperature">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-table
            ref="grid"
            title="근무자 목록"
            tableId="grid"
            :columns="grid.columns"
            :data="data.dailyUsers"
            :usePaging="false"
            :hideBottom="true"
            :columnSetting="false"
            :isFullScreen="false"
            :filtering="false"
            :isExcelDown="false"
            :editable="false"
            gridHeight="190px"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <component :is="component1" :param.sync="popupParam" />
          <component :is="component2" :param.sync="popupParam" />
          <component :is="component3" :param.sync="popupParam" />
          <component :is="component4" :param.sync="popupParam" />
          <component :is="component5" :param.sync="popupParam" />
          <component :is="component6" :param.sync="popupParam" />
          <component :is="component7" :param.sync="popupParam" />
          <component :is="component8" :param.sync="popupParam" />
          <component :is="component9" :param.sync="popupParam" />
          <component :is="component10" :param.sync="popupParam" />
          <component :is="component11" :param.sync="popupParam" />
          <component :is="component12" :param.sync="popupParam" />
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          plantCd: null,
          envWaterDailyResultId: '',
          isFullScreen: false,
          dailyResultDt: '',
          approvalStatusCd: null,
          approvalTarget: false,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      component1: null, // 콤포넌트
      component2: null, // 콤포넌트
      component3: null, // 콤포넌트
      component4: null, // 콤포넌트
      component5: null, // 콤포넌트
      component6: null, // 콤포넌트
      component7: null, // 콤포넌트
      component8: null, // 콤포넌트
      component9: null, // 콤포넌트
      component10: null, // 콤포넌트
      component11: null, // 콤포넌트
      component12: null, // 콤포넌트
      deleteUrl: '',
      data: {
        plantCd: null,  // 사업장코드
        envWaterDailyResultId: '',  // 수질일지 일련번호
        dailyResultDt: '',  // 작성일
        deptCd: '',  // 작성부서
        userId: '',  // 작성자
        weatherCd: null,  // 날씨
        temperature: '',  // 온도
        approvalStatusCd: '',
        dailyUsers: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'userName',
            field: 'userName',
            label: '근무자',
            align: 'center',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'startTime',
            field: 'startTime',
            label: '근무시작시간',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'endTime',
            field: 'endTime',
            label: '근무종료시간',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:30%',
            sortable: false,
          },
        ],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.water.daily.result.get.url;
      this.getDetail();
      
    },
    getDetail() {
      if (this.popupParam.envWaterDailyResultId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envWaterDailyResultId: this.popupParam.envWaterDailyResultId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.popupParam.plantCd = this.data.plantCd;
          this.popupParam.dailyResultDt = this.data.dailyResultDt;
          this.component1 = () => import(`${'@/pages/env/water/dailyReport01.vue'}`);
          this.component2 = () => import(`${'@/pages/env/water/dailyReport02.vue'}`);
          this.component3 = () => import(`${'@/pages/env/water/dailyReport03.vue'}`);
          this.component4 = () => import(`${'@/pages/env/water/dailyReport04.vue'}`);
          this.component5 = () => import(`${'@/pages/env/water/dailyReport05.vue'}`);
          this.component6 = () => import(`${'@/pages/env/water/dailyReport06.vue'}`);
          this.component7 = () => import(`${'@/pages/env/water/dailyReport07.vue'}`);
          this.component8 = () => import(`${'@/pages/env/water/dailyReport08.vue'}`);
          this.component9 = () => import(`${'@/pages/env/water/dailyReport09.vue'}`);
          this.component10 = () => import(`${'@/pages/env/water/dailyReport10.vue'}`);
          this.component11 = () => import(`${'@/pages/env/water/dailyReport11.vue'}`);
          this.component12 = () => import(`${'@/pages/env/water/dailyReport12.vue'}`);
        },);
      }
    },
  }
};
</script>